import React from "react";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Typography,
} from "@mui/material";

function ContactForm() {
  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        borderRadius: 2,
        // bgcolor: "background.paper",
        boxShadow: 3,
        alignSelf: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Please note, all fields are required
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            required
            sx={{ marginRight: 1, background: "#ffffff80" }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            sx={{ marginLeft: 1, background: "#ffffff80" }}
          />
        </Box>

        <TextField
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          required
          margin="normal"
          sx={{ background: "#ffffff80" }}
        />

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Looking for</FormLabel>
          <RadioGroup row aria-label="lookingFor" name="lookingFor">
            <FormControlLabel
              value="enquiries"
              control={<Radio />}
              label="for Enquiries"
            />
            <FormControlLabel
              value="business"
              control={<Radio />}
              label="for Business"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label="Let's start a conversation"
          variant="outlined"
          fullWidth
          required
          multiline
          rows={4}
          margin="normal"
          sx={{ background: "#ffffff80" }}
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default ContactForm;
