import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { BrowserRouter as Router } from "react-router-dom";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [scrolled, setScrolled] = useState(false);

  const [addMargin, setAddMargin] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width < 1024) {
      setAddMargin(true);
    } else {
      setAddMargin(false);
    }
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log("window , ", window.location.href);
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={"scrolled"}>
        <Container>
          <Navbar.Brand
            href="/"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/";
              onUpdateActiveLink("");
            }}
          >
            <img src={logo} alt="Logo" style={{ height: 68, width: 68 }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#about-us"
                className={
                  activeLink === "home" ||
                  window.location.href === "http://localhost:3000/about-us"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                style={{ marginTop: addMargin && 28 }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/about-us";
                  onUpdateActiveLink("home");
                }}
              >
                ArtCube
              </Nav.Link>
              <Nav.Link
                href="/#skills"
                className={
                  activeLink === "skills" ||
                  window.location.href === "http://localhost:3000/#skills"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                style={{ marginTop: addMargin && 28 }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/#skills";
                  onUpdateActiveLink("skills");
                }}
              >
                OurServices
              </Nav.Link>
              <Nav.Link
                href="/#projects"
                className={
                  activeLink === "projects" ||
                  window.location.href === "http://localhost:3000/#projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                style={{ marginTop: addMargin && 28 }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/#projects";
                  onUpdateActiveLink("projects");
                }}
              >
                Projects
              </Nav.Link>
              <Nav.Link
                href="#career"
                className={
                  activeLink === "career" ||
                  window.location.href === "http://localhost:3000/career"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                style={{ marginTop: addMargin && 28 }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/career";
                  onUpdateActiveLink("career");
                }}
              >
                Careers
              </Nav.Link>
              {/* <Nav.Link
                href="/#contact"
                className={
                  activeLink === "contact"|| window.location.href === "http://localhost:3000/#contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={(e) => {
                  onUpdateActiveLink("contact")}}
              >
                Contact Us
              </Nav.Link> */}
            </Nav>
            {/* <span className="navbar-text">
              <div className="social-icon">
                <a href="#">
                  <img src={navIcon1} alt="" />
                </a>
                <a href="#">
                  <img src={navIcon2} alt="" />
                </a>
                <a href="#">
                  <img src={navIcon3} alt="" />
                </a>
              </div>
              <HashLink to="#connect">
                <button className="vvd">
                  <span>Let’s Connect</span>
                </button>
              </HashLink>
            </span> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
